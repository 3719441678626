import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ISkillCategory } from "../../models/ISkillCategory";
import { SkillColumn, SkillWrapper } from "./styles";

interface ISkillsProps {
  skillCategories: ISkillCategory[];
}

export default class Skills extends Component<ISkillsProps> {
  constructor(public props: ISkillsProps) {
    super(props);
  }

  public render() {
    const { skillCategories } = this.props;
    return (
      <Grid container spacing={0}>
        {skillCategories.map(skillCategory => (
          <Grid key={skillCategory.name} item xs={3} sm={3} md={3} lg={3}>
            <SkillColumn>
              <Typography variant="subtitle2" gutterBottom>
                {skillCategory.name}
              </Typography>
              {skillCategory.skills.map(skill => (
                <SkillWrapper key={skill}>{skill}</SkillWrapper>
              ))}
            </SkillColumn>
          </Grid>
        ))}
      </Grid>
    );
  }
}
