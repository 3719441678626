import styled from "styled-components";

export const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: baseline;
`;

export const SkillsUsedList = styled.ul`
  list-style: none;
  li {
    display: inline;
    &::after {
      content: ", ";
    }
    :last-of-type {
      &::after {
        content: "";
      }
    }
  }
`;

export const ProjectContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;

  @media print {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

export const ResponsibilitiesList = styled.ul`
  list-style-type: square;
`;

export const SectionWrapper = styled.div`
  break-inside: avoid-page;
`;

export const RoleWrapper = styled.div`
  padding-left: 2px;
`;