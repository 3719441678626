import styled from "styled-components";
import { mobileBreakpoint } from "../../constants";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  width: 300px;
  li {
    text-align: right;
    font-size: 12px;
  }
  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: 32px;
  }
`;