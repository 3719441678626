import React from "react";
import ReactMarkdown from "react-markdown";
import { Typography } from "@material-ui/core";

interface IAboutProps {
  aboutText: string;
}

export default ({ aboutText }: IAboutProps) => (
  <Typography variant="body2">
    <ReactMarkdown source={aboutText} />
  </Typography>
) 