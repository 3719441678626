import React from "react";
import { IEducation } from "../../models/IEducation";
import { Typography } from "@material-ui/core";
import { EducationContainer, TitleAndDateContainer, RoleWrapper } from './styles'

interface IEducationProps {
  education: IEducation;
}

export default ({ education: { institution, level, major, yearAcquired } }: IEducationProps) => (
  <EducationContainer>
    <TitleAndDateContainer>
      <div>
        <Typography component="h5" variant="h5">
          {institution}
        </Typography>
        <RoleWrapper>
          <Typography variant="caption" gutterBottom>
            {`${level} in ${major}`}
          </Typography>
        </RoleWrapper>
      </div>

      <Typography variant="caption">{yearAcquired}</Typography>
    </TitleAndDateContainer>
  </EducationContainer>
)
