import React, { useState } from "react";
import Experience from "../Experience";
import Education from "../Education";
import Contact from "../Contact";
import About from "../About";
import classNames from "classnames";
import { IResume } from "../../models/IResume";
import Skills from "../Skills";
import ReactJson from "react-json-view";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CodeIcon from "@material-ui/icons/Code";
import DescriptionIcon from "@material-ui/icons/Description";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import {
  Avatar,
  Paper,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  Divider,
  Typography,
} from "@material-ui/core";
import { mobileBreakpoint } from "../../constants";

import styles, { Root, ResumeContainer, SideBarContainer, SkillsContainer, Header, LogoAndTitleWrapper, ContentContainer } from './styles';




interface IResumeProps extends WithTheme, WithStyles {
  resume: IResume;
}


const Resume = ({
  classes,
  resume,
  theme
}: IResumeProps) => {
  const {
    education,
    experience,
    contactInfo,
    aboutText,
    skillCategories,
    name,
    avatarUrl
  } = resume;
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [developerMode, setDeveloperMode] = useState(false);

  const handleClick = () => {
    setSpeedDialOpen(!speedDialOpen);
  };

  const toggleDeveloperMode = () => {
    setDeveloperMode(!developerMode);
    setSpeedDialOpen(false);
  };

  const mobile = window.innerHeight < mobileBreakpoint;
  const speedDialClassName = classNames(classes.speedDial, "print-hide");
  const paperClassName = classNames(classes.root, "paper");
  const avatarClass = classNames(classes.bigAvatar, "small-print");
  return (
    <Root>
      <SpeedDial
        open={speedDialOpen}
        icon={<SpeedDialIcon />}
        className={speedDialClassName}
        ariaLabel="Options"
        onClick={handleClick}
      >
        <SpeedDialAction
          icon={developerMode ? <DescriptionIcon /> : <CodeIcon />}
          tooltipTitle={developerMode ? "Reading Mode" : "Developer Mode"}
          onClick={toggleDeveloperMode}
          tooltipOpen={mobile}
        />
      </SpeedDial>
      {developerMode ? (
        <ReactJson src={resume} theme="eighties" />
      ) : (
          <ResumeContainer>
            <SideBarContainer theme={theme}>
              <Header>
                <LogoAndTitleWrapper>
                  <Avatar
                    src={avatarUrl}
                    alt="Mike Lowe"
                    className={avatarClass}
                  />
                </LogoAndTitleWrapper>
                <Contact contactInfo={contactInfo} />
              </Header>
              <Typography component="h4" variant="h4">
                {name}
              </Typography>
              <About aboutText={aboutText} />
            </SideBarContainer>
            <ContentContainer theme={theme}>
              <>
                <Paper className={paperClassName} square>
                  <Typography component="h4" variant="h4" gutterBottom>
                    Education
                  </Typography>
                  <Divider variant="fullWidth" />
                  {education.map((ed, index) => (
                    <Education key={`education_${index}`} education={ed} />
                  ))}
                </Paper>
                <Paper className={paperClassName} square>
                  <Typography component="h4" variant="h4" gutterBottom>
                    Experience
                  </Typography>
                  <Divider variant="fullWidth" />
                  {experience.map((ex, index) => (
                    <Experience key={`experience_${index}`} experience={ex} />
                  ))}
                </Paper>
                <Paper className={paperClassName} square>
                  <SkillsContainer>
                    <Typography component="h4" variant="h4" gutterBottom>
                      Skills
                    </Typography>
                    <Divider variant="fullWidth" />
                    <Skills skillCategories={skillCategories} />
                  </SkillsContainer>
                </Paper>
              </>
            </ContentContainer>
          </ResumeContainer>
        )}
    </Root>
  );
}

export default withStyles(styles)(withTheme()(Resume));
