import { IProject } from "../../models/IProject";
import React from "react";
import { Typography } from "@material-ui/core";
import { ProjectContainer, NameAndRoleContainer, RoleWrapper, SectionWrapper, ResponsibilitiesList, SkillsUsedList } from './styles';
interface IProjectProps {
  project: IProject;
}

export default ({
  project: {
    role,
    name,
    description,
    technologies,
    responsibilities
  }
}: IProjectProps) => (
    <ProjectContainer>
      <NameAndRoleContainer>
        <Typography variant="subtitle2" gutterBottom>
          {name}
        </Typography>
        <RoleWrapper>
          <Typography variant="caption">{` - ${role}`}</Typography>
        </RoleWrapper>
      </NameAndRoleContainer>
      <Typography variant="body1" paragraph>
        {description}
      </Typography>
      <SectionWrapper>
        <Typography variant="overline">Responsibilities:</Typography>
        <ResponsibilitiesList>
          {responsibilities.map((responsibility, index) => (
            <li key={`responsibility_${index}`}>{responsibility}</li>
          ))}
        </ResponsibilitiesList>
      </SectionWrapper>
      <SectionWrapper>
        <Typography variant="overline">Skills Used:</Typography>
        <SkillsUsedList>
          {technologies.map((technology, index) => (
            <li key={`technology_${index}`}>{technology}</li>
          ))}
        </SkillsUsedList>
      </SectionWrapper>
    </ProjectContainer>
  );
