import styled from "styled-components";

export const SkillColumn = styled.div`
  padding-top: 15px;
  text-align: center;
`;

export const SkillWrapper = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  @media print {
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 10pt;
  }
`;