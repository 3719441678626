import styled from "styled-components";
import { WithTheme } from "@material-ui/core";

export const TitleAndDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  break-inside: avoid-page;  
  border-bottom: 1px solid ${(props: WithTheme) => props.theme.palette.grey[500]};
`;

export const ExperienceContainer = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  @media print {
    margin-top: 5px;
  }
`;

export const ProjectsContainer = styled.div`
  border-left: 1px dashed ${(props: WithTheme) => props.theme.palette.grey[200]};
  padding-left: 12px;
  margin-bottom: 15px;
`;

export const RoleWrapper = styled.div`
  padding-left: 2px;
`;