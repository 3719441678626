import styled from "styled-components";

export const EducationContainer = styled.div`
  margin-top: 15px;
  @media print {
    margin-top: 5px;
  }
`;

export const TitleAndDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  break-inside: avoid-page;
`;

export const RoleWrapper = styled.div`
  padding-left: 2px;
`;