import React, { Component } from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Resume from "./components/Resume";
import { IResume } from "./models/IResume";
import mike from "./assets/mike.jpg";

const resume: IResume = {
  name: "Mike Lowe",
  avatarUrl: mike,
  contactInfo: {
    // address: "Unit 1103, 2677 Gladstone Street, Halifax NS",
    email: "mikelowe983@gmail.com",
    phone: "902.237.4051",
    website: "www.mikelowe.ca"
  },
  aboutText:
    "**Driven**, **creative** and **hard-working** software developer with a proven record of delivering high quality products at an enterprise level. Highly **detail-oriented** with great **problem solving** skills and a passion for developing **maintainable**, **testable** and **scalable** solutions. Primarily interested in Cross Platform Mobile, Single-Page web applications, DevOps, and serverless architecture.",
  education: [
    {
      institution: "St. Francis Xavier University",
      level: "Bachelor of Science",
      major: "Computer Science",
      yearAcquired: 2009
    },
    {
      institution: "NSCC (COGS)",
      level: "Advanced Diploma",
      major: "Geographic Information Systems",
      yearAcquired: 2010
    }
  ],
  experience: [
    {
      companyName: "MNP Digital (Formerly T4G LTD)",
      dateStarted: new Date("January 2, 2012"),
      role: "Senior Consultant, Developer",
      projects: [
        {
          name: "Human Capital Management Mobile Application",
          description:
            "Participated in the development of a cross-platform, metadata based, mobile application ",
          role: "Mobile Developer",
          responsibilities: [
            "Implemented, documented, and tested any assigned tasks.",
            "Reviewed colleagues' pull requests and provided feedback."
          ],
          technologies: ["React Native", "TypeScript"]
        },
        {
          name: "Mobile Retail Applications",
          description:
            "Participated in the development of a suite of cross-platform, brand-specific mobile applications for a major American off-price retail corporation.",
          role: "Mobile Developer",
          responsibilities: [
            "Developed 'Digital Rewards' module which was re-usable across various applications.",
            "Implemented 'Apple Wallet' style animations for browsing of gift cards and rewards certificates.",
            "Helped to produce proposal for multi-application refactor which would increase code reuse, decrease development time and improve code quality.",
            "Reviewed colleagues' pull requests and provided feedback."
          ],
          technologies: ["Xamarin Forms", "C#", ".Net"]
        },
        {
          name: "Blockchain 3D Printing Front End PoC",
          description:
            "Lead the development of a React front-end for SaaS PoC for a large parts manufacturer to securely distribute 3D printing packages which uses a blockchain backend to ensure the packages have not been altered in transit by a malicious 3rd party.",
          role: "Development Lead",
          responsibilities: [
            "Participated in discovery sessions on-site with client stakeholders.",
            "Provided estimates for the feature backlog.",
            "Coordinated with client technical team to ensure integration was successful.",
            "Provided guidance and support for the development team.",
            "Performed code-reviews and provided feedback to development team.",
            "Setup AzureDevOps build and release Pipelines.",
            "Used Azure ARM Templates to provision infrastructure (IaC)."
          ],
          technologies: ["Docker", "React", "TypeScript", "Microsoft Azure"]
        },
        {
          name: "Android Field Application for a Shipping & Logistics company",
          description:
            "Lead the development of a large-scoped project to build an Android application for drivers of a major shipping and logistics company. The application integrates with multiple back-end ERP systems and handles driver's going offline via Offline-Sync functionality.",
          role: "Development Lead",
          responsibilities: [
            "Participated in the application architecture design and evolution over the life of the project.",
            "Responsible for the design and implementation of user stories and foundational technical components.",
            "Created and maintained a complex data model using Entity Framework Core's Code-First approach.",
            "Provided guidance and support to a large and ever changing development team.",
            "Worked closely with client business and technical stakeholders to ensure the application met business needs and was properly integrated.",
            "Participated in the design and implementation of a custom Mobile Offline-Sync framework.",
            "Setup AzureDevOps build and release Pipelines."
          ],
          technologies: [
            "ASP.Net",
            "C#",
            "Entity Framework Core",
            "Microsoft Azure",
            "Visual Studio App Center",
            "Xamarin Forms"
          ]
        },
        {
          name: "Retail Marketing Web Application",
          description:
            "Participated in building a web application using AngularJS for a large scale department store to allow their marketing team to plan and sketch out their digital and print ads.",
          role: "2nd Developer",
          responsibilities: [
            "Responsible for the timely design and implementation of application features ranging from UI to back-end components.",
            "Worked closely with the development lead to design and implement the front end structure and formalize common patterns.",
            "Collaborated with the client technical team to develop an integration Data Contract."
          ],
          technologies: ["AngularJs", "ASP.Net", "C#", "TypeScript"]
        },
        {
          name: "Retail Planning and Analytics Applications",
          description:
            "Worked on two complimentary desktop applications built using WPF for a large scale department store to buy, plan and see historical and predictive analytics for their merchandise.",
          role: "Application Developer",
          responsibilities: [
            "Participated in the envisioning, effort estimation, design, and development of two highly used, and well regarded applications for retail planning and analytics.",
            "Responsible for the timely design and implementation of application features ranging from UI to back-end components.",
            "Liaised with client developers to define application contracts/interfaces for integration into their internal systems.",
            "Used various tools and techniques to assess application performance concerns and remedy issues found.",
            "Designed and implemented a shared code and styles library to be used across the company’s applications for unified functionality and look-and-feel.",
            "Worked closely with and managed a contractor to develop and integrate advanced visualization components into the application."
          ],
          technologies: [
            "C#",
            "WPF",
            "Syncfusion",
            "nHibernate",
            "MVVM Light",
            "SQL Server 2012"
          ]
        }
      ],
      awards: ["2018 T4G MVP (5 individuals chosen out of over 200)"]
    },
    {
      companyName: "National Public Relations",
      dateStarted: new Date("June 1, 2019"),
      dateEnded: new Date("July 30, 2019"),
      role: "Contractor Consultant",
      projects: [
        {
          name: "Port Permitting Application",
          description:
            "Worked with National to investigate several software defects with a legacy ASP.Net web application.",
          role: "Web Developer",
          responsibilities: [
            "Investigate several defects to identify root cause.",
            "Document findings.",
          ],
          technologies: [
            "ASP.Net",
            "C#",
          ]
        }
      ],
      awards: []
    },
    {
      companyName: "Colibri Software",
      dateStarted: new Date("October 1, 2016"),
      dateEnded: new Date("April 30, 2018"),
      role: "Contractor (intermittent)",
      projects: [
        {
          name: "Event Planner Web Application",
          description:
            "Worked with Colibri Software to build an MVP using Ruby on Rails and AngularJS. Later re-joined the project to assist in the development of a post-MVP product using Vue.js and NodeJS.",
          role: "Web Developer",
          responsibilities: [
            "Responsible for development and automated testing of various user stories.",
            "Investigated and addressed defects and performance issues.",
            "Designed the initial version of a GraphQL API."
          ],
          technologies: [
            "AngularJS",
            "CoffeeScript",
            "GraphQL",
            "MongoDB",
            "NodeJS",
            "Ruby on Rails",
            "Vue.js"
          ]
        }
      ],
      awards: []
    }
  ],
  skillCategories: [
    {
      name: "Technologies & Platforms",
      skills: [
        "Angular",
        "Android",
        "Autofac",
        "Bootstrap",
        "CakePHP",
        "Castle Windsor (IoC)",
        "Entity Framework Core",
        "GraphQL",
        "Microsoft .NET",
        "React",
        "Ruby on Rails",
        "Vue.js",
        "Xamarin/Xamarin Forms"
      ]
    },
    {
      name: "Languages",
      skills: [
        "C#",
        "CSS (SCSS)",
        "CoffeeScript",
        "C++",
        "HTML",
        "Java",
        "JavaScript",
        "JSON",
        "PHP",
        "Python",
        "Ruby",
        "Typescript",
        "XAML",
        "XML"
      ]
    },
    {
      name: "Tools",
      skills: [
        "Azure DevOps",
        "Azure Resource Manager",
        "Docker",
        "Gitlab",
        "Git",
        "Jira",
        "Microsoft Azure",
        "NPM/Yarn",
        "SVN",
        "Visual Studio",
        "Visual Studio Code",
        "Webpack"
      ]
    },
    {
      name: "Databases & Reporting",
      skills: [
        "MongoDB",
        "MySQL",
        "Oracle Spatial",
        "PostgreSQL",
        "SQLite",
        "SQL Server"
      ]
    }
  ]
};
class App extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <Resume resume={resume} />
      </>
    );
  }
}

export default App;
