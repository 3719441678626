import React from "react";
import Project from "../Project";
import { Divider, WithTheme, withTheme, Typography } from "@material-ui/core";
import { IExperience } from "../../models/IExperience";
import { ExperienceContainer, TitleAndDateContainer, RoleWrapper, ProjectsContainer } from './styles';

interface IExperienceProps extends WithTheme {
  experience: IExperience;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const formatDate = (date?: Date): string => {
  if (!date) {
    return "Present";
  }
  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

const Experience = ({
  theme,
  experience: {
    companyName,
    dateStarted,
    dateEnded,
    projects,
    awards,
    role
  }
}: IExperienceProps) => (
    <ExperienceContainer>
      <TitleAndDateContainer theme={theme}>
        <div>
          <Typography component="h5" variant="h5">
            {companyName}
          </Typography>
          <RoleWrapper>
            <Typography variant="caption" gutterBottom>
              {role}
            </Typography>
          </RoleWrapper>
        </div>

        <Typography variant="caption">
          {formatDate(dateStarted)} - {formatDate(dateEnded)}
        </Typography>
      </TitleAndDateContainer>
      <ProjectsContainer theme={theme}>
        <Typography variant="subtitle1" gutterBottom>
          Major Projects
        </Typography>
        {projects.map((project, index) => {
          if (index === projects.length - 1) {
            return <Project key={`project_${index}`} project={project} />;
          } else {
            return (
              <div key={`project_${index}`}>
                <Project project={project} />
                <Divider />
              </div>
            );
          }
        })}
      </ProjectsContainer>
      {awards.length > 0 ? (
        <>
          <Typography variant="subtitle1">Awards</Typography>
          <ProjectsContainer theme={theme}>
            <ul>
              {awards.map((award, index) => (
                <li key={`award_${index}`}>{award}</li>
              ))}
            </ul>
          </ProjectsContainer>
        </>
      ) : null}
    </ExperienceContainer>)

export default withTheme()(Experience);
