import { WithTheme, Theme } from "@material-ui/core";
import { mobileBreakpoint } from "../../constants";
import styled from "styled-components";
import { PositionProperty } from "csstype";

export default (theme: Theme) => {
    return {
        bigAvatar: {
            margin: 10,
            width: 130,
            height: 130
        },
        root: {
            ...theme.mixins.gutters(),
            margin: theme.spacing.unit * 2,
            paddingTop: theme.spacing.unit * 2,
            paddingBottom: theme.spacing.unit * 2
        },
        fab: {
            zIndex: 1000,
            position: "fixed" as PositionProperty,
            bottom: 20,
            right: 20
        },
        speedDial: {
            position: "fixed" as PositionProperty,
            bottom: theme.spacing.unit * 2,
            right: theme.spacing.unit * 3
        }
    };
};

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

export const LogoAndTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `;

export const ResumeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: stretch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: ${mobileBreakpoint}px) {
      flex-direction: column;
    }
  
    @media print {
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      flex-direction: column;
      position: relative;
      overflow: hidden;
    }
  `;

export const ContentContainer = styled.div`
    padding: ${(props: WithTheme) => props.theme.spacing.unit * 5}px;
    height: 100%;
    overflow-y: scroll;
    @media (max-width: ${mobileBreakpoint}px) {
      padding: ${(props: WithTheme) => props.theme.spacing.unit}px;
      overflow-y: visible;
    }
    @media print {
      overflow: hidden;
      padding: 0;
    }
  `;

export const SideBarContainer = styled.div`
    width: 360px;
    height: 100%;
    box-sizing: border-box;
    flex-basis: 360px;
    background: ${(props: WithTheme) => props.theme.palette.grey[200]};
    padding: ${(props: WithTheme) => props.theme.spacing.unit * 2}px;
    color: ${(props: WithTheme) =>
        props.theme.palette.getContrastText(props.theme.palette.grey[200])};
    @media (max-width: ${mobileBreakpoint}px) {
      width: 100%;
      height: auto;
    }
  
    @media print {
      width: 100%;
      height: auto;
      flex-basis: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
  `;

export const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  
    @media print {
      position: relative;
      width: 100%;
      overflow: visible;
    }
  `;

export const DevModeContainer = styled.div`
    position: fixed;
    right: 10px;
    top: 0;
    padding: 3px;
    z-index: 1000;
  `;

export const SkillsContainer = styled.div`
    break-inside: avoid-page;
  `;