import React from "react";
import { IContactInfo } from "../../models/IContactInfo";
import { List } from "./styles";

interface IContactProps {
  contactInfo: IContactInfo;
}

export default ({ contactInfo: { email, phone, website } }: IContactProps) => (
  <List>
    {/* <li>{address}</li> */}
    <li>{email}</li>
    <li>{phone}</li>
    <li id="website">{website}</li>
  </List>
)